module.exports = function menu() {
    var burger = document.querySelector('.burger');
    var menu = document.querySelector(".navigation")
    var head = document.querySelector('#header');
    burger.addEventListener('click', () => {
        if (burger.classList.contains('burger-active')) {
            burger.classList.remove('burger-active');
            menu.style.display = 'none';
        } else {
            burger.classList.add('burger-active');
            menu.style.display = 'flex';
        }
    })
    window.onscroll = function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 50) {
            head.style.boxShadow = '0 5px 5px 0 rgba(01, 01, 01, 0.5)';
        } else {
            head.style.boxShadow = '0 0 0 0 rgb(191, 228, 243)';
        }
    }
};
